import m from "mithril";
import { Popup } from "./Popup";

import * as p from "../../../gamja/node_modules/preact/dist/preact.module.js";

import { h } from "../../../gamja/node_modules/preact/dist/preact.module.js";
import htm from "../../../gamja/node_modules/htm/dist/htm.module.js";
export const html = htm.bind(h);

import App from "../../../gamja/components/app.js";
import * as linkifyjs from "../../../gamja/node_modules/linkifyjs/dist/linkify.module.js";
export { linkifyjs };

/**
 * toggle to show chat popup
 */
var showChat = false;
var chatRenderedFirstTime = false;

var changeInterval = null;

/**
 * sets a query param to 
 * dynamically define the
 * nickname for the chat
 * to avoid blocked names
 */
if(!window.location.search) {
  var d = new Date();
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set("nick", "konsumee" + d.getSeconds() + d.getMinutes() + d.getHours());
  window.location.search = searchParams.toString();
}

  /**
   * handle click actions
   * on bubble trigger to open popup
   */
function clickHandler() {
  showChat = !showChat;
  if(!chatRenderedFirstTime) {
    p.render(p.h(App), document.getElementById("irc-chat"))
    chatRenderedFirstTime = true;
  }
}

export const Widget = {
  onupdate: function() {
    changeInterval = window.setInterval(() => {
      var totalItems = document.getElementsByClassName("logline").length;
      if(totalItems) {
        document.getElementsByClassName("logline")[totalItems - 1].scrollIntoView();
      }
    }, 200);
  },
  onremove: function() {
    window.clearInterval(changeInterval);
  },
  view: function(vnode) {
    var ircChatStateClass = (!showChat ? "hide" : "show");
    return m("div.chat-widget", [
      m(`div#irc-chat`, { class: ircChatStateClass }),
      m("div.trigger-bubble", {
        onclick: clickHandler,
      }, [
        m("img", {
          src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMAAwgJEBk0TVheY2R5eo+ut8jb5OXs8fX2+cjRDTIAAADsSURBVHgBldZbkoMgFIThRgQv8SKKgGf/C51UnJqaRI30/9zfe+NQUQ3TvG7bOk9DVeCmshmj/CuOTYnrdBfkUOg0zlOtl9OWVuEk4+QyZ3DIevmSt/ioTvK1VH/s5bY3YdM9SBZ/mUUyWgx+U06ycgp7D8msxSvtc4HXL9BLdj2elSEfhBJAI0QNgJEBI1BEBsQClVBVGDgwYOLAhJkDM1YOrNg4sLFAsLJgZsHEgoEFFQt0JAFGFjQsKAMJ0LFAexKgZYFyJIDxJIBNJEDNAtSJBLCeBDCOBFAPzwFA94ED+zmhwDO9358r8ANtIsMXi7qVAwAAAABJRU5ErkJggg==",
          alt: "chatbubble-icon"
        })
      ]),
    ])
  }
}