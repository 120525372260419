import m from "mithril";

export const Popup = {
  view: function(vnode) {
    return m("div.chat-popup", [
      m("iframe", {
        src: "https://kiwiirc.com/nextclient/?settings=3f38b0e7ba721268c8b1c9ac841b0064",
      })
    ]);
  }
}