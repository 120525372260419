import m from "mithril";
export const Content = {
  todo: m.trust(`<svg class="w-16 h-16 mb-4" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
<rect class="fill-current text-purple-600" width="64" height="64" rx="32" />
<g fill="none" fill-rule="evenodd">
    <path class="stroke-current text-purple-300" d="M40 22a2 2 0 012 2v16a2 2 0 01-2 2H24a2 2 0 01-2-2V24a2 2 0 012-2" stroke-width="2" stroke-linecap="square" />
    <path class="stroke-current text-purple-100" stroke-width="2" stroke-linecap="square" d="M36 32l-4-3-4 3V22h8z" />
</g>
</svg>`),
};
