import m from "mithril";

export const Item = {
  view: function(vnode) {
    var stateClass = vnode.attrs.active ? 'block' : 'hidden';
    return m("div.w-full", {class: stateClass}, [
      m("article.relative max-w-md mx-auto md:max-w-none", [
        m("figure.md:absolute md:inset-y-0 md:right-0 md:w-1/2", [
          m("img.w-full h-full object-cover", { src: vnode.attrs.media?.src, alt: vnode.attrs.media?.alt }),
        ]),
        m(".relative bg-gray-800 py-8 md:py-16 px-6 md:pr-16 md:max-w-lg lg:max-w-xl", [
          m("h4.h4 mb-2", vnode.attrs.title),
          m("p.text-lg text-gray-400", m.trust(vnode.attrs.description.replace(/(?:\r\n|\r|\n)/g, "<br/>"))),
        ])
      ])
    ])
  }
}