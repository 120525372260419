import m from "mithril";
import { Social as SocialIcons } from "../Icons/Social";
import { SVG } from "polythene-mithril"

export const BottomLine = {
  view: function(vnode) {
    return m("ul.flex mb-4 md:order-1 md:ml-4 md:mb-0", [
      m("li.ml-4", [
        m("a.flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out", { href: "https://codeberg.org/konsumi", target: "_blank", ariaLabel: "Codeberg" }, [
          m(SVG, SocialIcons.codeberg)
        ]),
      ])
    ])
  }
}