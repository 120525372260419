import m from "mithril";
import { Info } from "./Info";
import { BlockList } from "./BlockList";
import { BottomLine } from "./BottomLine";

import { FooterProject } from "../../models/FooterProject";

export const Footer = {
  view: function(vnode) {
    return m("footer", [
      m(".py-12 md:py-16", [
        m(".max-w-6xl mx-auto px-4 sm:px-6", [
          m(".grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12", [
            m("div.md:col-span-4 lg:col-span-5", [
              m(Info)
            ]),
            m("div.md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8", [
              m("div", ""),
              m("div.text-sm", [
                m("h6.text-gray-200 font-medium mb-1", "Project Board"),
                m("ul", [
                  m("li.mb-1", [
                    m("p.text-gray-400", "Oliver Schörwerth"),
                    m("p.text-gray-400", "Querstraße 17"),
                    m("p.text-gray-400", "39264 Wertlau"),
                    m("a.text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out", { href: "mailto:olli@konsumi.rocks?Subject=Hello%20konsumi" }, "olli @ konsumi.rocks"),
                  ])
                ])
              ]),
              m(BlockList, { title: "Project", items: FooterProject.navigationBlock })
            ]),
          ]),
          m(".md:flex md:items-center md:justify-between", [
            m(BottomLine),
            m("div.text-gray-400 text-sm mr-4", "© 2022 - konsumi - erlebe virtuelle interaktion neu - offen und selbstbestimmt"),
          ]),
          m("div.text-gray-600 text-sm", [
            m("a", { href: "https://www.freepik.com/free-vector/abstract-technology-design_1165261.htm" }, "Image by pinnacleanimates on Freepik"),
          ]),
          m("div.text-gray-600 text-sm", [
            m("a", { href: "https://www.freepik.com/free-vector/space-rocket-flying-space-with-moon-stars-background-print-vector-illustration_1158410.htm" }, "Image by macrovector on Freepik"),
          ])
        ])
      ])
    ])
  }
}
