import m from "mithril";
import { Brand } from "../Icons/Brand";
import { SVG } from "polythene-mithril"

export const Logo = {
  view: function (vnode) {
    return m(".flex-shrink-0 mr-4", [
      m("a.block", { href: "/", ariaLabel: "konsumi" }, [
        m("div.w-32 h-8 fill-current font-bold m-2 mt-0 text-lg text-gray-200",
          m(SVG, Brand.logoFull)
        ),
      ]),
    ]);
  },
};
