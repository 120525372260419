import m from "mithril";
import { SVG } from "polythene-mithril";
import { Contact } from "../Form/Contact";
import { Brand } from "../Icons/Brand";
import { Section } from "../Section/Section";

export const Simple = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6 relative",
        childrenClass: "pt-10 pb-12 md:pt-5 md:pb-5",
      },
      [
        m(".absolute left-0 bottom-0 -ml-20 lg:block pointer-events-none", [
          m(SVG, Brand.leaf1),
        ]),
        m(".relative pt-32 pb-10 md:pt-40 md:pb-16", [
          m(".max-w-3xl mx-auto text-center pb-12 md:pb-16", [
            m("h1.mb-4 text-5xl font-bold	", vnode.attrs.title),
            m("p.mb-20 text-2xl mt-8", vnode.attrs.description),
              m(Contact)
          ]),
        ]),
      ]
    );
  },
};
