import m from "mithril";
var img = require('../../../public/assets/images/hedgerabbhog-v3.png');


export const HeroImage = {
  view: function(vnode) {
    return m("section", [
      m(".relative flex justify-center items-center mt-8", [
        m("img.mx-auto", { src: img}),
      ])
    ])
  }
}
