import m from "mithril";
import { Home as HomeContent } from "../models/StaticHome";
import { Process as Facts } from "../components/Process/Process";
import { Hero } from "../components/Hero/Hero";
import { Tabs } from "../components/Tabs/Tabs";
import { Target } from "../components/Target/Target";
import { Simple as SimpleHero } from "../components/Hero/Simple";
import { Text as TextBlock } from "../components/Block/Text";

var heroContent = HomeContent.getElements()["hero-video"];
var whatContent = HomeContent.getElements()["what-is-konsumi"];
var whyContent = HomeContent.getElements()["kirschen"];
var aimContent = HomeContent.getElements()["fakten"];
var futureContent = HomeContent.getElements()["future"];
var casesContent = HomeContent.getElements()["cases"];
var collabContent = HomeContent.getElements()["collaborate"];
var roadmapContent = HomeContent.getElements()["roadmap"];

import { SVG } from "polythene-mithril";
import { Brand } from "../components/Icons/Brand";

export const Home = {
  view: function () {
    return m("div", [
      m(Hero, {
        buttons: heroContent.actions,
        title: heroContent.title,
        description: heroContent.description,
        media: heroContent.media,
      }),
      m(".absolute right-0 top-10 hidden lg:block pointer-events-none", [
        m(SVG, Brand.leaf2)
      ]),
      m(Facts, {
        title: whatContent.title,
        subtitle: whatContent.subtitle,
        description: whatContent.description,
        items: whatContent.items
      }),
      m(Tabs, {
        title: whyContent.title,
        description: whyContent.description,
        tabs: whyContent.tabs,
        currentTab: 0
      }),
      m(Target, {
        title: aimContent.title,
        image: "",
        subtitle: aimContent.subtitle,
        items: aimContent.items,
        description: aimContent.description
      }),
      m(Facts, {
        title: futureContent.title,
        subtitle: futureContent.subtitle,
        description: futureContent.description,
        items: futureContent.items
      }),
      m(Target, {
        title: casesContent.title,
        image: "",
        subtitle: casesContent.subtitle,
        items: casesContent.items,
        description: casesContent.description
      }),
      m(TextBlock, {
        title: roadmapContent.title,
        subtitle: roadmapContent.subtitle,
        buttons: roadmapContent.actions,
      }),
      m(SimpleHero, {
        buttons: collabContent.actions,
        title: collabContent.title,
        description: collabContent.description,
      }),
    ]);
  },
};