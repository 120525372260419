import m from "mithril";
import { SVG } from "polythene-mithril"
import { Icons } from"./Icons";
import { Navigation as NavigationModel } from "../../models/Navigation";
var mobileMenuExpanded = false;

function toggleMobileMenu() {
  mobileMenuExpanded = !mobileMenuExpanded;
}

export const Navigation = {
  view: function (vnode) {
    return m(".nav-wrapper", [
      m("nav.hidden md:flex md:flex-grow", [
        m(
          "ul.flex flex-grow justify-end flex-wrap items-center",
          NavigationModel.mainNavigationItems.map(function (item) {
            return m("li", [
              m(
                "a.text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out",
                { href: item.href },
                item.title
              ),
            ]);
          })
        ),
      ]),
      m("div.md:hidden", [
        m(
          "button.hamburger",
          {
            class: mobileMenuExpanded ? "active" : "",
            onclick: toggleMobileMenu,
            ariaExpanded: mobileMenuExpanded,
            ariaControls: "mobile-nav",
          },
          [
            m(SVG, Icons.hamburger)
          ]
        ),
        m(
          "nav.absolute top-full z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out",
          {
            xRef: "mobileNav",
            onclickoutside: function () {
              mobileMenuExpanded = false;
            },
            class: (mobileMenuExpanded ? 'block' : 'hidden')
          },
          [
            m(
              "ul.bg-gray-800 px-4 py-2",
              NavigationModel.mainNavigationItems.map(function (item) {
                return m("li", [
                  m(
                    "a.flex text-gray-300 hover:text-gray-200 py-2",
                    { href: item.href },
                    item.title
                  ),
                ]);
              })
            ),
          ]
        ),
      ]),
    ]);
  },
};
