import m from "mithril";

export const Contact = {
  view: function (vnode) {
    return m("form.max-w-xl mx-auto mt-12", { action: "https://forms.konsumi.rocks/", method: "post" }, [
        m("h3.h3 my-8", "Schreib uns direkt"),
        m("input", { type: "hidden", value: "Contact Form", name: "_formName" }),
        m("input", { type: "hidden", value: "https://konsumi.rocks/#thank-you", name: "_redirectTo" }),
        m("input", { type: "email", value: "", name: "_t_email", style: "display: none;" }),
        m("div.mb-4", [
          m("div.w-full px-3", [
            m("label.block text-gray-300 text-sm font-medium mb-1", { for: "email"}, "E-Mail"),
            m("input.form-input w-full text-gray-300", { id: "email", type: "email", name: "_replyTo", required: true })
          ]),
        m("div.mb-4", [
          m("div.w-full px-3 mt-8", [
            m("label.block text-gray-300 text-sm font-medium mb-1", { for: "Message"}, "Nachricht"),
            m("textarea.form-textarea w-full text-gray-300", { rows: 4, id: "Message", name: "Message", required: true })
          ]),
        ]),
        m("div.mt-6", [
          m("div.w-full px-3", [
            m("button.form-input w-full text-gray-300", { id: "Send", type: "submit", name: "Send", required: true }, "Senden")
          ])
        ])
      ])
    ])
  }
};
