import m from "mithril";
import { SVG } from "polythene-mithril";
import { Widget as Chat } from "../Chat/Widget";
import { HeroImage } from "./HeroImage";
import { Section } from "../Section/Section";
import { Brand } from "../Icons/Brand";

export const Hero = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6 relative",
        childrenClass: "pt-10 pb-12 md:pt-16 md:pb-20",
      },
      [
        m("#chat-container", m(Chat)),
        m(".absolute left-0 bottom-0 -ml-20 lg:block pointer-events-none", [
          m(SVG, Brand.leaf1),
        ]),
        m(".relative pt-32 pb-10 md:pt-40 md:pb-16", [
          m(".max-w-3xl mx-auto text-center pb-12 md:pb-16", [
            m("h1.mb-5 text-4xl md:text-7xl font-bold	leading-10 ", vnode.attrs.title),
            m("p.mb-20 text-xl md:text-2xl mt-10", vnode.attrs.description),
            m(HeroImage, { media: vnode.attrs.media }),
          ]),
        ]),
      ]
    );
  },
};
