import m from "mithril";

export const Button = {
  /**
   * handle click actions
   * @param {*} vnode
   */
  clickHandler: function (vnode) {
    if (vnode.attrs?.action !== undefined) {
      vnode.attrs.action();
    }
  },

  view: function (vnode) {
    return m(
      "a.btn",
      {
        href: vnode.attrs.href,
        class: vnode.attrs.class,
        onclick: this.clickHandler.bind(vnode),
      },
      vnode.attrs.label
    );
  },
};
