import m from "mithril";
import { sort as sortHelper } from "../../helper/sort";
import { Header as SectionHeader } from "../Section/Header";
import { Item as ProcessItem } from "./Item";
import { Section } from "../Section/Section";
import { Content } from "../Icons/Content";

export const Process = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6",
        childrenClass: "pt-10 pb-12 md:pt-16 md:pb-20",
      },
      [
        m(SectionHeader, {
          title: vnode.attrs.title,
          subtitle: vnode.attrs.subtitle
        }),
        m('div.max-w-3xl mx-auto text-center pb-12', [
          m('p.text-xl text-gray-400', vnode.attrs.description),
        ]),
        m(
          "div.max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none",
          sortHelper.sortBy(vnode.attrs.items, 'priority').map(function (item) {
            return m("div", [
              m(ProcessItem, { title: item.title, description: item.subtitle, icon: Content[item.icon] }),
            ])
          })
        ),
      ]
    );
  },
};
