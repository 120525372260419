import m from "mithril";
import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { SubscribeForm } from "../../components/Footer/SubscribeForm";

export const Landing = {
  view: function (vnode) {
    return m(
      "main.k-app font-inter antialiased bg-gray-900 text-gray-200 tracking-tight",
      [
        m(".flex flex-col overflow-hidden", m(Header)),
        m("main.flex-grow", [
          m("section", vnode.children),
          m(SubscribeForm),
        ]),
        m(Footer),
      ]
    );
  },
};
