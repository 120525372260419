import m from "mithril";

export const FooterProject = {
    navigationBlock: [
        { title: 'about', href: 'https://docs.konsumi.rocks/' },
        { title: 'mission', href: 'https://docs.konsumi.rocks/mission/' },
        { title: 'team', href: 'https://docs.konsumi.rocks/team/' }
    ],
}

