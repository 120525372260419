import m from "mithril";

export const Section = {
  view: function (vnode) {
    return m("section", [
      m("div", { class: vnode.attrs.class }, [
        m("div", { class: vnode.attrs.childrenClass }, vnode.children),
      ]),
    ]);
  },
};
