import m from "mithril";

export const Item = {
  view: function(vnode) {
    return m("div.mt-6", [
      m("article.relative max-w-md mx-auto md:max-w-none", [
        m("h4.h4 mb-2", { class: vnode.attrs.colorClasses }, [
          m("span", { class: vnode.attrs.colorClasses }, "."),
          m("span", vnode.attrs.title)
        ]),
        m("p.text-lg text-gray-400", vnode.attrs.content)
      ])
    ])
  }
}