import m from "mithril";
// import cHome from '../../content/home.json' assert { type: 'json' };
import cHome from '../../content/home.json';
//cHome
export const Home = {
  data: null,
  getElements: function () {
    // if(!this.data) {
    //   await this.load();

    //   m.redraw()
    // }
    return cHome;
  },
  /**
   * load data on runtime
   */
  load: async function () {
    await fetch(cHome)
    .then(async (response) => {
      this.data = await response.json();
    })
    .catch(function (err) {
    });
    
  },
};

