import m from "mithril";
import { Home } from "./views/Home";
import { Landing as LandingLayout } from "./layouts/Landing/Landing";

import "./app.scss";
m.route(document.body, "", {
    "/": {
        render: function() {
            return m(LandingLayout, m(Home))
        }
    }
});
