import m from "mithril";
import { sort as sortHelper } from "../../helper/sort";
import { Button } from "../Form/Button";
import { Header as SectionHeader } from "../Section/Header";
import { Section } from "../Section/Section";

export const Text = {
  view: function (vnode) {
    return m(
      Section,
      {
        class: "max-w-6xl mx-auto px-4 sm:px-6",
        childrenClass: "pt-10 pb-4 md:pt-16 md:pb-1",
      },
      [
        m(SectionHeader, {
          title: vnode.attrs.title,
          subtitle: vnode.attrs.subtitle
        }),
        m("div.max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center mt-2", 
          sortHelper.sortBy(vnode.attrs.buttons, 'priority').map(function (item) {
            return m("div.mx-1", [
              m(Button, {
                class: `${item.classes} w-full`,
                label: item.title,
                href: item.href,
                action: item.action
              }),
            ]);
          })),
      ]
    );
  },
};
