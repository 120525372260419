import m from "mithril";
export const Icons = {
  more:
    m.trust(`<svg class="w-3 h-3 fill-current text-purple-400 flex-shrink-0 ml-2" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 5H0v2h6v4l6-5-6-5z" />
                                    </svg>`),
  button:
    m.trust(`<svg class="w-4 h-4 fill-current text-purple-600 mr-2" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 9v6a8 8 0 008-8V1a8 8 0 00-8 8zM0 6v3a6 6 0 006 6v-3a6 6 0 00-6-6z" />
</svg>`)
}